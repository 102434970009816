<template>
  <div class="fill-height">
    <div class="py-2 px-4 elevation-2 page-error-bar" v-if="showError">
      <v-icon color="white">mdi-alert-outline</v-icon>
      <span class="white--text pl-3">{{ deleteResumeError }}</span>
    </div>
    <v-container fluid class="container-main">
      <v-row class="pb-2">
        <v-col cols="12" sm="6" md="8" lg="9">
          <h2>{{ name }}</h2>
          <p class="text-body-1 font-italic text--secondary mb-1">
            Saved On {{ dateSubmittedFormatted }}
          </p>
          <div class="mb-2 scroll-multiline">
            <p
              class="text-body-1 font-italic text--secondary mb-0"
              v-for="(note, i) in noteArr"
              :key="note + i"
            >
              {{ note }}
            </p>
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-btn outlined block @click="nav('/SavedResumes')">
            Back to Your Resumes
          </v-btn>
          <v-btn outlined block @click="download()" class="mt-1">
            Download .docx
          </v-btn>
          <v-btn outlined block @click="triggerRemove()" class="mt-1"> Delete Saved Resume</v-btn>
        </v-col>
      </v-row>
      <v-system-bar color="accent" height="5" class="mb-4"></v-system-bar>
      <review-comment
        :currentHref="`/ResumeReview/${id}`"
        :imageRef="imageRef"
        :errorOnLoad="errorOnLoad"
      ></review-comment>
    </v-container>
    <r-dialog
      v-model="removeDialog"
      title="Confirm Removal"
      persistent
      transition="dialog-top-transition"
    >
      <v-overlay :absolute="true" v-if="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-card-text class="text--primary text-body-1 pt-4 pb-3">
        <p class="text-body-1 text--primary mb-0">
          Are you sure you want to remove this saved resume?
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between align-end pb-3">
        <v-btn color="gray" outlined @click="removeDialog = false"
          >Cancel</v-btn
        >
        <v-btn color="primary" outlined @click="removeResume">Remove</v-btn>
      </v-card-actions>
    </r-dialog>
  </div>
</template>

<script>
import * as disp from "../utility/display.js";
import ReviewAndComment from "../components/ReviewAndComment.vue";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import * as dUtil from "../utility/dateutil";

// @ is an alias to /src
export default {
  name: "ResumeReview",
  data: () => ({
    errorOnLoad: false,
    removeDialog: false,
    loading: false,
    removeId: null,
  }),
  components: {
    "review-comment": ReviewAndComment,
  },
  mounted() {
    let pageKey = this.$route.params.key;
    if (this.id != pageKey) {
      this.id = pageKey;
    }

    this.setErrorOnLoad();

    this.loadReviewUser({ reviewId: this.id }).then(this.setErrorOnLoad);
  },
  methods: {
    ...mapActions("currentReview", ["loadReviewUser", "removeSavedResume"]),
    setErrorOnLoad() {
      if (!disp.IsNullorWhitespace(this.loadResumeError)) {
        this.errorOnLoad = true;
      } else {
        this.errorOnLoad = false;
      }
    },
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    download() {
      const link = document.createElement("a");
      link.href = this.fileRef;
      link.target = "_blank";
      document.body.append(link);
      link.click();
      link.remove();
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    },
    triggerRemove() {
      this.removeDialog = true;
      this.removeId = this.id;
    },
    async removeResume() {
      try {
        this.loading = true;
        await this.removeSavedResume({ reviewId: this.removeId });
        if (!this.showError)
        {
          this.nav('/SavedResumes');        
        }
        
      } finally {
        this.removeDialog = false;
        this.loading = false;
      }
    },
  },
  computed: {
    showError() {
      return !disp.IsNullorWhitespace(this.deleteResumeError);
    },
    ...mapFields("currentReview", [
      "deleteResumeError",
      "loadResumeError",
      "comments",
      "imageRef",
      "fileRef",
      "name",
      "note",
      "dateSubmitted",
      "userDisplay",
      "userId",
      "id",
    ]),
    dateSubmittedFormatted() {
      return dUtil.dateStringToFormat(this.dateSubmitted);
    },
    noteArr() {
      if (this.note == null || this.note == "") {
        return [];
      }

      let noteSplit = this.note.split("\n");
      if (noteSplit.length == 1) {
        noteSplit[0] = "Note: " + noteSplit[0];
      } else {
        noteSplit.unshift("Note: ");
      }

      return noteSplit;
    },
  },
};
</script>


<style scoped>
.error-box {
  width: 636px;
  height: 816px;
  background-color: var(--v-resumeErrorBox-base);
  color: var(--v-resumeErrorText-base);
}

.error-box-content {
  width: 70%;
}

.resume-placeholder {
  width: 612px;
  height: 792px;
  background-color: var(--v-resumePlaceholder-base);
}

.resume-backing {
  background-color: var(--v-resumeBacker-base);
  max-height: 816px;
  max-width: 636px;
}

.page-error-bar {
  margin-left: -8px;
  margin-right: -8px;
  background-color: var(--v-error-base);
}

.resume-img {
  max-width: 100%;
  height: auto;
}
</style>