<template>
  <v-row class="d-flex justify-center">
    <v-col cols="12" lg="8" xl="6" class="d-flex justify-center px-5" ref="stageContainer">
      <div v-if="errorOnLoad">
        <div class="d-flex flex-column justify-center align-center error-box">
          <div class="pb-5 error-box-content">
            <h1>Ouch!</h1>
            <h2>We couldn't load your resume!</h2>
          </div>
          <div class="error-box-content">
            <div class="font-italic pb-2">
              We're looking into the problem - in the meantime you can try
              reloading the page:
            </div>
            <v-btn @click="refresh(currentHref)" outlined>Refresh</v-btn>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="resume-backing pa-3 mb-4">
          <v-stage
            :config="stageConfig"
          >
            <v-layer :config="layerConfig">
              <v-image
                :config="{
                  image: resumeImage,
                  ...imageConfig,
                }"
              >
              </v-image>
            </v-layer>
          </v-stage>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import * as log from "../utility/logging/logger";

// @ is an alias to /src
export default {
  name: "ReviewAndComment",
  data: () => ({
    stageConfig: {
      width: 612,
      height: 792,
    },
    refStageConfig: {
      width: 612,
      height: 792,
    },
    stageWidth: 612,
    resizeTimeout: null,
    localComments: [],
    layerConfig: {},
    newComment: "",
    showNewComment: false,
    isDrawing: false,
    resumeImage: null,
    imageConfig: {},
  }),
  props: {
    imageRef: {
      type: String,
    },
    currentHref: {
      type: String,
    },
    errorOnLoad: {
      type: Boolean,
    },
  },
  computed: {
  },
  watch: {
    value: {
      handler() {
        this.setupAll();
      },
    },
  },
  created() {
    window.addEventListener("resize", this.resizeHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  mounted() {
    this.setupAll();
  },
  methods: {
    resizeHandler() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(() => {
        this.setupAll();
      }, 100);
    },
    setupAll() {
      let maxWidth = this.$refs.stageContainer.offsetWidth;
      let hwr = this.refStageConfig.height / this.refStageConfig.width;

      // calc stage values
      let newStageWidth = maxWidth - 12 * 2;
      this.stageConfig.width = newStageWidth;
      this.stageConfig.height = newStageWidth * hwr;

      // reload image
      this.stageWidth = newStageWidth;
      this.loadImage();
    },
    buildImageConfig(imgWidth, imgHeight, maxWidth) {
      const targetHeight = (maxWidth * imgHeight) / imgWidth;
      return { width: maxWidth, height: targetHeight };
    },
    loadImage() {
      let image = new window.Image();
      image.src = this.imageRef;
      image.onload = () => {
        this.resumeImage = image;
        this.imageConfig = this.buildImageConfig(
          image.width,
          image.height,
          this.stageWidth
        );
        this.stageConfig.height = this.imageConfig.height;
      };
    },
    refresh(href) {
      log.logWarning(
        "User triggered refresh of Resume page due to error.",
        "Review.refresh"
      );
      window.location.href = href;
    },
  },
};
</script>

<style scoped>
.error-box {
  width: 636px;
  height: 816px;
  background-color: var(--v-resumeErrorBox-base);
  color: var(--v-resumeErrorText-base);
}

.error-box-content {
  width: 70%;
}

.resume-placeholder {
  width: 612px;
  height: 792px;
  background-color: var(--v-resumePlaceholder-base);
}

.resume-backing {
  background-color: var(--v-resumeBacker-base);
  /* max-width: 636px; */
}

.resume-img {
  max-width: 100%;
  height: auto;
}
</style>